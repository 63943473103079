import { apiClient } from "@netgame/openapi";

import type {
	NextTournaments,
	PrevTournaments,
	TournamentDataWinner,
	TournamentDataWinners,
	TournamentData
} from "@/types";

// TODO - compare with global hook useRace and maybe use it instead of local
const useFunrizeRace = (race?: NextTournaments | PrevTournaments) => {
	const { open } = useFunrizeModals();
	const { data: tournamentData } = useGetTournamentData();
	const { data: appInitData } = useAppInitData();

	const loadingSubscribtions = ref(false);

	const isSubscribed = computed(
		() =>
			tournamentData.value.data?.isSubscribed ||
			appInitData.value?.TournamentsSubscriptions?.includes((race?.id || tournamentData.value.data?.id) ?? 0)
	);
	const currentUser = computed(() => tournamentData.value?.playerData);

	const winners = computed(() => {
		const initialWinners = (tournamentData.value?.data?.winners ?? []) as TournamentDataWinners;

		if (race?.isActive ?? tournamentData.value.data?.isActive) {
			const isCurrentUserWinner = initialWinners.some((item) => item.profile === currentUser.value?.profile);

			const filteredWinners = initialWinners.filter((winner) => winner.profile !== currentUser.value?.profile);
			if (!isCurrentUserWinner && isSubscribed.value) {
				return [
					...filteredWinners,
					{
						name: currentUser.value?.name,
						place: currentUser.value?.place,
						points: currentUser.value?.points,
						position: currentUser.value?.place,
						profile: currentUser.value?.profile,
						rounds: currentUser.value?.roundsPlayed,
						city: "",
						state: "",
						uuid: ""
					} as TournamentDataWinner
				];
			}
			return initialWinners;
		}

		return race?.winners ?? initialWinners;
	});

	const sortedWinners = computed(() => winners.value?.filter((winner) => winner.points !== 0));

	const activeWinners = computed(() => {
		if (!sortedWinners.value?.length) {
			return [];
		}
		const currentUserIndex: number =
			sortedWinners.value?.findIndex((item) => item?.profile === appInitData.value?.profile) || -1;

		if (currentUserIndex <= 0) {
			return sortedWinners.value?.slice(0, 3);
		}

		if (currentUserIndex === sortedWinners.value?.length - 1) {
			return sortedWinners.value?.slice(-3);
		}

		return sortedWinners.value?.slice(currentUserIndex - 1, currentUserIndex + 2);
	});

	const handleSubscribe = (id: number) => {
		if (isSubscribed.value) {
			return;
		}

		apiClient({
			path: "/rest/tournaments/{id}/subscribe/",
			method: "get",
			parameters: { path: { id } },
			options: {
				onRequest: () => {
					loadingSubscribtions.value = true;
				},
				onResponse: ({ response }) => {
					loadingSubscribtions.value = false;
					if (response._data.success) {
						if (appInitData.value?.TournamentsSubscriptions) {
							appInitData.value.TournamentsSubscriptions.push(id);
						}
					}
				},
				onRequestError: () => {
					loadingSubscribtions.value = false;
				},
				onResponseError: () => {
					loadingSubscribtions.value = false;
				}
			}
		});
	};

	const handleLeaderboardModalClick = () => {
		const data = (race ?? tournamentData.value.data ?? {}) as TournamentData;

		dispatchGAEvent({
			event: "click_button",
			location: "view_leaderboard",
			button_name: isSubscribed.value ? "continue racing" : "join",
			form_name: "Race"
		});
		open("LazyOModalLeaderboard", {
			title: data.title,
			id: Number(data.historyId || data.history_id)
		});
	};

	const handlePrizesModalClick = () => {
		const { title = "", prizes = [] } = race ?? tournamentData.value.data ?? {};
		open("LazyOModalShowPrizes", { title, prizes });
	};

	const handleOpenRulesClick = () => {
		open("LazyOModalRacesRules");
	};

	return {
		tournamentData,
		loadingSubscribtions,
		activeWinners,
		sortedWinners,
		winners,
		isSubscribed,
		currentUser,
		handleSubscribe,
		handlePrizesModalClick,
		handleLeaderboardModalClick,
		handleOpenRulesClick
	};
};

export default useFunrizeRace;
